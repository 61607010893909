.textContainer {
  padding: 0.3rem 2.65rem;
}
.textContainer .title {
  font-size: 0.64rem;
}
.textContainer .date {
  font-size: 0.32rem;
}
.textContainer h1 {
  font-size: 0.48rem;
}
.textContainer ul{
  list-style: none;
  margin: 0;
  padding: 0;
}
.textContainer h3{
  font-size: 0.42rem;
  margin-bottom: 0.24rem;
}

.textContainer h4 {
  font-size: 0.32rem;
  margin-bottom: 0.24rem;
}
.textContainer p {
  line-height: 1.4;
  margin-bottom: 0.2rem;
  font-size: 0.28rem;
}
.textContainer a {
  color: #1c60ff;
}
@media screen and (max-width: 1400px) {
  .textContainer {
    padding: 0.3rem 0.6rem;
 }
}
