.home {
  width: 100vw;
  min-height: 100vh;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  font-size: 1rem;
  color: black;
  background: url('../../assets/img/bg.png') repeat center;
  background-size: cover;
  background-color: #010025;
  .header{
    border-bottom: .01rem solid rgba(255, 255, 255, 0.1);
    .container{
      display: flex;
      justify-content: center;
      align-items: center;
      padding: .3rem 2.65rem;
      .space{
        flex: 1;
      }
      .logo{
        display: flex;
        align-items: center;
        img{
          height: .3rem;
        }
      }
      .nav{
        display: flex;
        align-items: center;
        justify-content: center;
        &-item{
          font-size: .18rem;
          color: #fff;
          padding: 0 .15rem;
        }
      }
    }
  }
  .body{
    padding-top: .98rem;
    .container{
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      .logo{
        width: 2.6rem;
        img{
          width: 100%;
        }
      }
      .paragraph{
        color: #fff;
        font-size: .28rem;
        font-weight: 500;
        max-width: 9.5rem;
        text-align: center;
        padding: 0 .2rem;
        margin-top: .32rem;
      }
      .download{
        margin-top: 1rem;
        p{
          text-align: center;
          font-size: .24rem;
          color: rgba(255, 255, 255, 0.5);
        }
        .btn{
          display: flex;
          align-items: center;
          justify-content: center;
          .item{
            width: 1.92rem;
            margin: 0 .44rem;
            img{
              width: 100%;
            }
          }
        }
      }
      .email{
        font-size: .2rem;
        font-weight: 500;
        margin-top: .4rem;
        display: flex;
        align-items: center;
        justify-content: center;
        .href{
          display: flex;
          align-items: center;
          justify-content: center;
          line-height: 1;
          vertical-align: middle;
          margin: 0 .2rem;
          .img{
            width: .36rem;
            margin-right: .05rem;
            margin-top: .08rem;
            img{
              width: 100%;
            }
          }
          color: #ffffff6a;
        }
      }
    }
  }
}


@media screen and (max-width: 1400px){
  .home {
    .header{
      .container{
        padding: 0.3rem .6rem;
      }
    }
  }
}